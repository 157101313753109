import React from "react"
import logo from '../../assets/img/logo/logo5.webp'

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="fallback-spinner d-flex align-items-center justify-content-center m-auto vh-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="loader"></div>
                </div>
            </div>
        )
    }
}

export default SpinnerComponent